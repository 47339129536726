<template>
    <div>
        <video class="video_presentation" autoplay loop buffered muted preload="auto">
            <source :playbackRate="0.5" src="../assets/Duhamel-Logistique-Export-1.mp4" type="video/mp4">
        </video>
        <div id="wrapper" class="d-flex flex-row">
            <div class="info1 d-flex">
                <div class="circle">
                    <img class="perfume-icon" src="../assets/perfume.svg" alt="Groupe">
                </div>
                <div class="info-txt d-flex flex-column">
                    <div class="d-flex txt-container">
                        <number
                                class="nb-entreport"
                                :class="{scaleBig: scaleClass}"
                                ref="number2"
                                :from="numberFrom1"
                                :format="theFormat"
                                :to="numberTo1"
                                :duration="duration"
                                easing="Power4.easeOut"
                                @complete="completed"/>
                        <span class="txt-entrepot">ENTREPÔTS</span>
                    </div>
                </div>
            </div>
            <div class="info2 d-flex">
                <div class="circle">
                    <img class="warehouse-icon" src="../assets/warehouse.svg">
                </div>
                <div class="info-txt d-flex flex-column">
                    <div class="d-flex txt-container">
                        <number
                                class="nb-surface"
                                :class="{scaleBig: scaleClass}"
                                ref="number2"
                                :from="numberFrom2"
                                :format="theFormat"
                                :to="numberTo2"
                                :duration="duration"
                                easing="Power4.easeOut"
                                @complete="completed"/>
                        <span class="txt-surface">M² DE STOCKAGE</span>
                    </div>
                </div>
            </div>
            <div class="info3 d-flex">
                <div class="circle">
                    <img class="card-icon" src="../assets/card.svg">
                </div>
                <div class="info-txt d-flex flex-column">
                    <div class="d-flex txt-container">
                        <number
                                class="nb-commande"
                                :class="{scaleBig: scaleClass}"
                                ref="number2"
                                :from="numberFrom3"
                                :format="theFormat"
                                :to="numberTo3"
                                :duration="duration"
                                easing="Power4.easeOut"
                                @complete="completed"/>
                        <span class="txt-commandes">COMMANDES/JOUR</span>
                    </div>
                </div>
            </div>
            <div class="info4 d-flex">
                <div class="circle">
                    <img class="delivery-icon" src="../assets/delivery.svg">
                </div>
                <div class="info-txt d-flex flex-column">
                    <div class="d-flex txt-container">
                        <span class="nb-engagement">ENGAGEMENT</span>
                        <span class="txt-engagement">LIVRAISONS J+1</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="skills d-flex flex-row">
            <div class="skill-ecommerce">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/solutions-e-commerce.jpg">
                <router-link class="skill-txt-ecommerce" to="/solutions">E-commerce</router-link>
            </div>
            <div class="skill-luxe">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/Logistique-luxe.jpg">
                <router-link class="skill-txt-luxe" to="/solutions">Produits de luxe</router-link>
            </div>
            <div class="skill-logistique">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/logistique-Industrielle.jpg">
                <router-link class="skill-txt-logistique" to="/solutions">Logistique Industrielle</router-link>
            </div>
            <div class="skill-copacking">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/copacking.jpg">
                <router-link class="skill-txt-copacking" to="/solutions">Copacking</router-link>
            </div>
        </div>
        <div class="text-innovation">
            <p class="text_innovation_txt">Hugues, Florian, Estelle, Guillaume...<br>
                tous à votre service</p>
        </div>
        <div class="team d-flex flex-row">
            <div class="team-cart cart-1">
                <div class="team-cart-txt">
                    <p><br>
                        </p>
                </div>
            </div>
            <div class="team-cart cart-2">
                <div class="team-cart-txt">
                    <p>- Guillaume -<br>
                        Directeur Duhamel Transit International</p>
                </div>
            </div>
            <div class="team-cart cart-3">
                <div class="team-cart-txt">
                    <p>- Romain -<br>
                        Directeur Technique</p>
                </div>
            </div>
            <div class="team-cart cart-4">
                <div class="team-cart-txt">
                    <p>- Estelle -<br>
                        Responsable Commerciale</p>
                </div>
            </div>
            <div class="team-cart cart-5">
                <div class="team-cart-txt">
                    <p>- Hugues -<br>
                        Président</p>
                </div>
            </div>
            <div class="team-cart cart-6">
                <div class="team-cart-txt">
                    <p>- Florian -<br>
                        Directeur Général</p>
                </div>
            </div>
            <div class="team-cart cart-7">
                <div class="team-cart-txt">
                    <p>- Elodie -<br>
                        Responsable RH</p>
                </div>
            </div>
            <div class="team-cart cart-8">
                <div class="team-cart-txt">
                    <p>- Guillaume -<br>
                        Directeur N'ine Informatique</p>
                </div>
            </div>
            <div class="team-cart cart-9">
                <div class="team-cart-txt">
                    <p><br>
                        </p>
                </div>
            </div>
        </div>
        <hr class="separation">
        <div class="nos-client d-flex flex-column">
            <h3 class="client-title">Nos clients parlent de nous</h3>
            <hr>
            <WpClientPosts></WpClientPosts>
        </div>
    </div>
</template>

<script>
    import WpClientPosts from "./WpClientPosts";

    export default {
        components: {
            WpClientPosts,
        },
        data() {
            return {
                numberFrom1: 0,
                numberTo1: 9,
                duration: 5,
                scaleClass: false,
                numberFrom2: 0,
                numberTo2: 116000,
                numberFrom3: 0,
                numberTo3: 5000,
                numberFrom4: 0,
                numberTo4: 1,
            };
        },
        methods: {
            theFormat(number) {
                return Intl.NumberFormat('fr-FR', {maximumSignificantDigits: 3}).format(number)
            },
            completed() {
                console.log("animation finish");
            }
        },
    };
</script>

<style scoped>
    .video_presentation {
        margin-top: 60px;
        width: 100%;
    }

    #wrapper {
        background-color: #182630;
        width: 100%;
        margin-top: -9px;
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;
    }

    .circle {
        margin-top: 7px;
        margin-bottom: 7px;
        border-radius: 100%;
        height: 34px;
        width: 33px;
        background-color: white;
        justify-content: center;
    }

    .txt-container {
        flex-direction: column;
    }

    .info1 {
        width: 50%;
    }

    .info2 {
        width: 50%;
    }

    .info3 {
        width: 50%;
    }

    .info4 {
        width: 50%;
    }

    .info-txt {
        text-align: left;
    }

    .perfume-icon {
        width: 55%;
        margin-top: 5px;
    }

    .nb-entreport {
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 11px;
        margin-left: 5px;
    }

    .txt-entrepot {
        font-size: 8px;
        margin-left: 5px;
        color: #ffffff;
    }

    .warehouse-icon {
        width: 85%;
        margin-top: 9px;
    }

    .nb-surface {
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 11px;
        margin-left: 5px;
    }

    .txt-surface {
        font-size: 8px;
        margin-left: 5px;
        color: #ffffff;
    }

    .card-icon {
        width: 70%;
        margin-top: 9px;
    }

    .nb-commande {
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 11px;
        margin-left: 5px;
    }

    .txt-commandes {
        font-size: 8px;
        margin-left: 5px;
        color: #ffffff;
    }

    .delivery-icon {
        width: 75%;
        margin-top: 5px;
    }

    .nb-engagement {
        font-size: 8px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 11px;
        margin-left: 5px;
    }

    .txt-engagement {
        font-size: 8px;
        margin-left: 5px;
        color: #ffffff;
    }

    a:hover {
        text-decoration: none;
    }

    .skills {
        flex-wrap: wrap;
    }

    .skill-ecommerce {
        width: 50%;
    }

    .skill-ecommerce img {
        width: 100%;
    }

    .skill-luxe {
        width: 50%;
    }

    .skill-luxe img {
        width: 100%;
    }

    .skill-logistique {
        width: 50%;
    }

    .skill-logistique img {
        width: 100%;
    }

    .skill-copacking {
        width: 50%;
    }

    .skill-copacking img {
        width: 100%;
    }

    .skill-txt-ecommerce {
        position: relative;
        top: -50%;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: rgba(0, 0, 0, .5);
        padding: 20px 30px;
    }

    .skill-txt-luxe {
        position: relative;
        top: -50%;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: rgba(0, 0, 0, .5);
        width: 110px;
        padding: 20px 30px;
    }

    .skill-txt-logistique {
        position: relative;
        top: -50%;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: rgba(0, 0, 0, .5);
        padding: 20px 25px;
    }

    .skill-txt-copacking {
        position: relative;
        top: -50%;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: rgba(0, 0, 0, .5);
        padding: 20px 30px;
    }

    .text-innovation {
        font-size: 24px;
    }

    .text_innovation_txt {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .team {
        flex-wrap: wrap;
        background-color: #000000;
    }

    .team-cart {
        width: 50%;
        height: 300px;
        background-color: #000000;
    }

    .team-cart-txt {
        height: 100px;
        background-color: #525452;
        color: white;
        font-size: 18px;
        padding: 10px;
        margin-top: 200px;
    }

    .cart-1 {
        /*background-image: url("");*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-2 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/10/guillaumenoirblanc.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-3 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/3.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-4 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/4.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-5 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/5.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-6 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/6.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-7 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/7.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-8 {
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/8.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .cart-9 {
        /*background-image: url("");*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .separation {
        height: 35px;
        width: 100%;
        background-color: #EA670C;
        margin: 0;
    }

    .nos-client {
        background-color: #F6F6F6;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 30px;
        text-align: center;
    }

    .client-title {
        font-size: 24px;
        margin-top: 8px;
        margin-bottom: 17px;

    }

    @media (min-width: 576px) {
        .video_presentation {
            margin-top: 0;
        }

        .nb-entreport {
            font-size: 12px;
            margin-top: 6px;
        }

        .txt-entrepot {
            font-size: 12px;
        }

        .nb-surface {
            font-size: 12px;
            margin-top: 6px;
        }

        .txt-surface {
            font-size: 12px;
        }

        .nb-commande {
            font-size: 12px;
            margin-top: 6px;
        }

        .txt-commandes {
            font-size: 12px;
        }

        .nb-engagement {
            font-size: 12px;
            margin-top: 6px;
        }

        .txt-engagement {
            font-size: 12px;
        }

        .skill-txt-ecommerce {
            font-size: 16px;
        }

        .skill-txt-luxe {
            font-size: 16px;
        }

        .skill-txt-logistique {
            font-size: 16px;
        }

        .skill-txt-copacking {
            font-size: 16px;
        }

        .team-cart {
            width: 33.33%;
        }

        .client-title {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    @media (min-width: 768px) {
        .video_presentation {
            margin-top: 0;
        }

        #wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }

        .circle {
            height: 44px;
            width: 43px;
        }

        .nb-entreport {
            font-size: 16px;
            margin-top: 7px;
        }

        .txt-entrepot {
            font-size: 16px;
        }

        .nb-surface {
            font-size: 16px;
            margin-top: 7px;
        }

        .txt-surface {
            font-size: 16px;
        }

        .card-icon {
            width: 70%;
            margin-top: 11px;
        }

        .nb-commande {
            font-size: 16px;
            margin-top: 7px;
        }

        .txt-commandes {
            font-size: 16px;
        }

        .nb-engagement {
            font-size: 16px;
            margin-top: 7px;
        }

        .txt-engagement {
            font-size: 16px;
        }

        .skill-txt-ecommerce {
            font-size: 18px;
        }

        .skill-txt-luxe {
            font-size: 18px;
        }

        .skill-txt-logistique {
            font-size: 18px;
        }

        .skill-txt-copacking {
            font-size: 18px;
        }

        .team-cart {
            width: 33.33%;
            height: 400px;
        }

        .team-cart-txt {
            height: 100px;
            background-color: #525452;
            color: white;
            font-size: 18px;
            padding: 10px;
            margin-top: 300px;
        }
    }

    @media (min-width: 992px) {
        #wrapper {
            height: 135px;
            justify-content: center;
        }

        .info1 {
            width: 140px;
            margin-right: 20px;
        }

        .info2 {
            width: 180px;
            margin-right: 20px;
        }

        .info3 {
            width: 180px;
            margin-right: 20px;
        }

        .info4 {
            width: 250px;
        }

        .circle {
            height: 54px;
            width: 53px;
            margin-top: 40px;
        }

        .nb-entreport {
            font-size: 36px;
            font-weight: 600;
            margin-top: 25px;
        }

        .txt-entrepot {
            font-size: 13px;
        }

        .warehouse-icon {
            margin-top: 12px;
        }

        .nb-surface {
            font-size: 36px;
            font-weight: 600;
            margin-top: 25px;
        }

        .txt-surface {
            font-size: 13px;
        }

        .card-icon {
            margin-top: 13px;
        }

        .nb-commande {
            font-size: 36px;
            font-weight: 600;
            margin-top: 25px;
        }

        .txt-commandes {
            font-size: 13px;
        }

        .delivery-icon {
            margin-top: 8px;
        }

        .nb-engagement {
            font-size: 30px;
            font-weight: 600;
            margin-top: 30px;
        }

        .txt-engagement {
            font-size: 13px;
        }

        .skill-ecommerce {
            width: 25%;
            filter: grayscale(1);
        }

        .skill-ecommerce:hover {
            filter: grayscale(0);
        }

        .skill-luxe {
            width: 25%;
            filter: grayscale(1);
        }

        .skill-luxe:hover {
            filter: grayscale(0);
        }

        .skill-logistique {
            width: 25%;
            filter: grayscale(1);
        }

        .skill-logistique:hover {
            filter: grayscale(0);
        }

        .skill-copacking {
            width: 25%;
            filter: grayscale(1);
        }

        .skill-copacking:hover {
            filter: grayscale(0);
        }

        .skill-txt-ecommerce {
            font-size: 20px;
        }

        .skill-txt-luxe {
            font-size: 20px;
        }

        .skill-txt-logistique {
            font-size: 20px;
            padding: 20px 20px;
        }

        .skill-txt-copacking {
            font-size: 20px;
        }

        .team-cart {
            width: 11.11%;
            height: 300px;
        }

        .team-cart {
            transition-property: top;
            -webkit-transition-property: top;
            transition-duration: .5s;
            transition-timing-function: ease-out;
            top: 0;
            position: relative;
        }

        .team-cart:hover {
            top: -90px;
        }

        .team-cart-txt {
            height: 90px;
            margin-top: 211px;
            font-size: 12px;
            padding-top: 10px;
        }

        .separation {
            position: relative;
            height: 90px;
        }

        .client-title {
            margin-top: 20px;
            margin-bottom: 27px;
        }
    }

    @media (min-width: 1200px) {
        .info1 {
            width: 150px;
            margin-right: 100px;
        }

        .info2 {
            width: 190px;
            margin-right: 100px;
        }

        .info3 {
            width: 190px;
            margin-right: 100px;
        }

        .info4 {
            width: 330px;
        }

        .circle {
            height: 64px;
            width: 63px;
            margin-top: 35px;
        }

        .card-icon {
            margin-top: 17px;
        }

        .skill-txt-ecommerce {
            font-size: 24px;
        }

        .skill-txt-luxe {
            font-size: 24px;
        }

        .skill-txt-logistique {
            font-size: 24px;
        }

        .skill-txt-copacking {
            font-size: 24px;
        }

        .team-cart {
            height: 315px;
            transition-property: top;
            -webkit-transition-property: top;
            transition-duration: .5s;
            transition-timing-function: ease-out;
            top: 0;
            position: relative;
        }

        .team-cart:hover {
            top: -110px;
        }

        .team-cart-txt {
            height: 110px;
            margin-top: 315px;
            font-size: 16px;
            padding-top: 10px;
        }

        .separation {
            position: relative;
            height: 110px;
        }

        .client-title {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    @media (min-width: 1600px) {
        .circle {
            height: 64px;
            width: 63px;
            margin-top: 35px;
        }

        .skill-txt-ecommerce {
            font-size: 24px;
        }

        .skill-txt-luxe {
            font-size: 24px;
        }

        .skill-txt-logistique {
            font-size: 24px;
        }

        .skill-txt-copacking {
            font-size: 24px;
        }

        .team-cart {
            height: 315px;
            transition-property: top;
            -webkit-transition-property: top;
            transition-duration: .5s;
            transition-timing-function: ease-out;
            top: 0;
            position: relative;
        }

        .team-cart:hover {
            top: -90px;
        }

        .team-cart-txt {
            height: 90px;
            margin-top: 315px;
            font-size: 16px;
            padding-top: 10px;
        }

        .separation {
            position: relative;
            height: 90px;
        }

        .client-title {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

</style>
import { render, staticRenderFns } from "./Newsletters.vue?vue&type=template&id=319cbed9&scoped=true"
import script from "./Newsletters.vue?vue&type=script&lang=js"
export * from "./Newsletters.vue?vue&type=script&lang=js"
import style0 from "./Newsletters.vue?vue&type=style&index=0&id=319cbed9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319cbed9",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./Group.vue?vue&type=template&id=b06c7582&scoped=true"
import script from "./Group.vue?vue&type=script&lang=js"
export * from "./Group.vue?vue&type=script&lang=js"
import style0 from "./Group.vue?vue&type=style&index=0&id=b06c7582&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b06c7582",
  null
  
)

export default component.exports